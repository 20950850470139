import React, {Component} from 'react';
import "../styles/footer.css";
class Footer extends Component {
    render() {
        return (
            <div className={"footer-content"}>
                <ul className={"ul-footer"}>
                    {/*<li className={'li-footer'}><p className={'a-footer'}>Petri-96@hotmail.com</p></li>*/}
                </ul>
            </div>
                );
    }
}

export default Footer;