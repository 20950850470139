import {useState} from 'react';
import "../styles/contact.css";
import {useLanguage} from "../common/LanguageSelector";

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const unSupportedWords =
    [
        "porn", "!!!!", "????", "singles", "XXX", "near you", "Earn $",
        "Earn extra cash", "$$$", "Online marketing", "spam",
        "gay", "lesbian", "hot new pictures", "credit", "dating", "sex", "cock"
    ]

const unSupportedEmailDomains = [".fun"];

const finnishBadWords = ["perkele", "saatana", "vittu", "homo", "perkele", "helvetti", "kyrpä", "perse", "pillu"]

const contactTexts = {
    "eng": {
        "contact": "Contact",
        "name": "Name",
        "email": "Email",
        "phone": "Phone",
        "message": "Message",
        "send": "Send",
        "emailSent": "Email sent",
        "thanks": "Thank you for reaching out to me!\n" +
            "I have received your message. \n" +
            "I aim to respond to messages as soon as possible.\n" +
            "Best regards: Petri Sarajärvi"
    },
    "fin": {
        "contact": "Ota yhteyttä",
        "name": "Nimi",
        "email": "Sähköposti",
        "phone": "Puhelinnumero",
        "message": "Viesti",
        "send": "Lähetä",
        "emailSent": "Sähköposti lähetetty",
        "thanks": "Kiitos yhteydenotostasi! Olen vastaanottanut viestisi.\n" +
            "Pyrin vastaamaan viesteihin mahdollisimman pian.\n" +
            "\nYstävällisin terveisin Petri Sarajärvi"
    }
}

const emailValTexts = {
    "eng": {
        "name": "Invalid name",
        "email": "Invalid email",
        "message": "The message is too short",
        "domain": "invalid email domain",
        "word": "Word",
        "noMessageChar": "Invalid word in the message.",
    },
    "fin": {
        "name": "Ei kelvollinen nimi",
        "email": "Ei kelvollinen email",
        "message": "Viesti on liian lyhyt",
        "domain": "ei ole kelvollinen email domain.",
        "word": "Sana",
        "noMessageChar": "ei ole kelvollinen viestissä.",
    }
}

export const Contact = () => {
    const [data, setData] = useState({name: "", email: "", message: "", tel: "", submitted: false})
    const [isLoading, setLoading] = useState(false);

    const {getLanguage} = useLanguage();
    const language = getLanguage();

    const goHome = () => {
        alert(contactTexts[language].emailSent);
        setData({
            name: "",
            email: "",
            message: "",
            tel: "",
            submitted: true,
        })
        setLoading(false)
    }

    const formValidation = () => {
        if (data.name.length < 3) {
            alert(emailValTexts[language].name);
            return false
        }
        if (!data.email.includes('@')) {
            alert(emailValTexts[language].email);
            return false
        }
        if (data.message.length < 10) {
            alert(emailValTexts[language].message)
            return false
        }

        let foundUnSupportedEmail = unSupportedEmailDomains.find(itm => data.email.toLowerCase().includes(itm));
        if (foundUnSupportedEmail) {
            alert(`${foundUnSupportedEmail} ${emailValTexts[language].domain}`)
            return false
        }

        let foundUnSupportedWord = unSupportedWords.find(itm => data.message.toLowerCase().includes(itm));
        if (foundUnSupportedWord) {
            alert(`${emailValTexts[language].word} ${foundUnSupportedWord} ${emailValTexts[language].noMessageChar}`)
            return false
        }

        let foundFinnishWord = finnishBadWords.find(itm => data.message.toLowerCase().includes(itm));
        if (foundFinnishWord) {
            alert(`${emailValTexts[language].word} ${foundFinnishWord} ${emailValTexts[language].noMessageChar}`)
            return false
        }
        return true;
    }

    const handleSubmit = e => {
        setLoading(true)
        e.preventDefault();
        if (formValidation()) {
            fetch("/", {
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: encode({"form-name": "contact", ...data})
            })
                .then(() => goHome())
                .catch(error => {
                        alert(error)
                        setLoading(false)
                    }
                );
        }
        setLoading(false)
    };

    const handleChange = e => {
        const {name, value} = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const {name, email, message, tel} = data;

    if (!data.submitted) {
        return (
            <div className={'contact-page'}>
                <h1 className={"yth1"}>{contactTexts[language].contact}</h1>
                <form id="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className={"label-text"}>
                            {contactTexts[language].name} <input className="form-control" type="text" name="name"
                                                                 defaultValue={name}
                                                                 onChange={handleChange}/>
                        </label>
                    </div>
                    <div className="form-group">
                        <label className={"label-text"}>
                            {contactTexts[language].email} <input className="form-control" type="email" name="email"
                                                                  defaultValue={email}
                                                                  onChange={handleChange}/>
                        </label>
                    </div>
                    <div className="form-group">
                        <label className={"label-text"}>
                            {contactTexts[language].phone} <input type={'number'} className="form-control" name="tel"
                                                                  defaultValue={tel}
                                                                  onChange={handleChange}/>
                        </label>
                    </div>
                    <div className="form-group">
                        <label className={"label-text"}>
                            {contactTexts[language].message} <textarea className="form-control" name="message"
                                                                       defaultValue={message} rows={"5"}
                                                                       onChange={handleChange}/>
                        </label>
                    </div>
                    <div className={"submit-div"}>
                        <button disabled={isLoading} className="btn-primary" type="submit">
                            {contactTexts[language].send}
                        </button>
                    </div>
                </form>
            </div>
        );
    } else {
        return (
            <div className={'contact-page'}>
                <h1 className={"thanks-submit"}>
                    {contactTexts[language].thanks}
                </h1>
            </div>
        )
    }

}