import * as React from 'react'
import {useContext} from "react";

const LanguageContext = React.createContext();

function languageSelector(state, action) {
    switch (action.type) {
        case "getLang": {
            return state?.origLang ?? "eng"; // Return default value if origLang is null
        }
        case "setLang": {
            return { origLang: action.lang };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function LanguageProvider({ children }) {
    const [state, dispatch] = React.useReducer(languageSelector, {origLang: "eng"}); // Initialize state to null
    const getLanguage = () => {
        return state.origLang; // Return default value if origLang is null
    };

    const setLanguage = (lang) => {
        dispatch({ type: "setLang", lang: lang });
    };
    return (
        <LanguageContext.Provider value={{ state, getLanguage, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}

function useLanguage() {
    if (!useContext(LanguageContext)) {
        throw new Error('useLanguage must be used within a LanguageProvider')
    }
    const { state, setLanguage, getLanguage } = useContext(LanguageContext);
    return {
        state, setLanguage, getLanguage
    }
}

export {LanguageProvider, useLanguage}