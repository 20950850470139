import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NavBar from './common/NavBar';
import Footer from './common/Footer';
import {NotFound} from "./pages/NotFound";
import {Home} from "./pages/Home";
import {LanguageProvider} from "./common/LanguageSelector";
import {Projects} from "./pages/Projects";
import {QuickLook} from "./pages/QuickLook";
import {Contact} from "./pages/Contact";

export const App = () => {
    return (
        <BrowserRouter>
            <div className="homebck">
                <LanguageProvider>
                    <NavBar/>
                    <Routes>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/projects" element={<Projects/>}/>
                        <Route path="/skills" element={<QuickLook/>}/>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="*" element={() => <NotFound/>}/>
                    </Routes>
                    <Footer/>
                </LanguageProvider>
            </div>
        </BrowserRouter>
    );
}
