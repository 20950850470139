var ChartListJson = {
    "charts": [
        {
            "fin": "Ohjelmointi",
            "eng": "Programming",
            "languages": [
                {
                    "language": "JavaScript",
                    "skill": "5",
                },
                {
                    "language": "CSS",
                    "skill": "4",
                },
                {
                    "language": "HTML",
                    "skill": "5",
                },
                {
                    "language": "Python",
                    "skill": "4",
                },

                {
                    "language": "Java",
                    "skill": "3",
                },
                {
                  "language": "Dart",
                  "skill": "3",
                },
                {
                    "language": "C",
                    "skill": "2",
                },

                {
                    "language": "C++",
                    "skill": "3",
                },
                {
                    "language": "Swift",
                    "skill": "2",
                },

                {
                    "language": "PHP",
                    "skill": "2",
                },

                {
                    "language": "Git",
                    "skill": "4",
                },
                {
                    "language": "GraphQL",
                    "skill": "2",
                },
                {
                    "language": "Gatsby",
                    "skill": "2",
                }
            ]
        },
        {
            "fin": "Kehitysympäristöt",
            "eng": "Frameworks / Tools",
            "languages": [
                {
                    "language": "React",
                    "skill": "5",
                },

                {
                    "language": "Microsoft office tools",
                    "skill": "4",
                },

                {
                    "language": "GitLab",
                    "skill": "4",
                },
                {
                    "language": "WebStorm",
                    "skill": "5",
                },

                {
                    "language": "Visual Studio Code",
                    "skill": "5",
                },

                {
                    "language": "Android Studio",
                    "skill": "4",
                },

                {
                    "language": "Atom",
                    "skill": "3",
                },

                {
                    "language": "NetBeans",
                    "skill": "2",
                },

                {
                    "language": "MySQL Workbench",
                    "skill": "3",
                },
                {
                    "language": "Arduino",
                    "skill": "3",
                },
            ]
        },

        {
            "fin": "Käyttöjärjestelmät",
            "eng": "Operating Systems",
            "languages": [
                {
                    "language": "Raspbian",
                    "skill": "3",
                },

                {
                    "language": "Mbed",
                    "skill": "2",
                },

                {
                    "language": "Windows",
                    "skill": "5",
                },

                {
                    "language": "Linux",
                    "skill": "2",
                },
            ]
        },
        {
            "fin": "Tietokannat",
            "eng": "Databases",
            "languages": [
                {
                    "language": "MySQL",
                    "skill": "4",
                },

                {
                    "language": "MongoDB",
                    "skill": "3",
                },
                {
                    "language": "MariaDB",
                    "skill": "2",
                },
            ]
        },
        ]
};

export default ChartListJson