import React, {useState, useEffect} from "react";
import "../styles/navbar.css";
import {CSSTransition} from "react-transition-group";
import {Link} from "react-router-dom";
import {FaEllipsisH} from 'react-icons/fa';
import {useLanguage} from "./LanguageSelector";

const navTexts = {
    "eng": {
        "projects": "Projects",
        "skills": "Skills",
        "contact": "Contact"
    },
    "fin": {
        "projects": "Projektit",
        "skills": "Taidot",
        "contact": "Ota yhteyttä"
    }
}

export default function NavBar() {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const { getLanguage } = useLanguage();
    const language = getLanguage();

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };
    const getNavContent = () => {
        return (
            <nav className="Nav">
                <Link className={"liItems"} to={"/projects"} onClick={toggleNav}>
                    {navTexts[language].projects}
                </Link>
                <Link className={"liItems"} to={"/skills"} onClick={toggleNav}>
                    {navTexts[language].skills}
                </Link>
                <Link className={"liItems"} to={"/contact"} onClick={toggleNav}>
                    {navTexts[language].contact}
                </Link>

            </nav>
        )
    };
    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    return (
        <header className="Header">
            <Link className={"linkToHome"} to={"/"}> <img className="logoNav" src={"/petrisaimg.png"} alt=""/></Link>

            <CSSTransition
                in={!isSmallScreen || isNavVisible}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >

                {getNavContent()}
            </CSSTransition>
            <button onClick={toggleNav} className="IconItem">
                <i className={"iLogo"}><FaEllipsisH/></i>
            </button>
        </header>
    );
}