import React, { useEffect, useState } from 'react';
import "../styles/projects.css";
import projectListJson from "../projects_list/ProjectListJson";
import Spinner from "../common/Spinner";
import {useLanguage} from "../common/LanguageSelector";


export const Projects = () => {
    const [projectList, setProjectList] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [activeProject, setActiveProject] = useState(null);
    const [isModalOpen, toggleModal] = useState(false);
    const [modalImgUrl, setModalImgUrl] = useState(null);
    const [imageLoaded, setImageLoaded] = useState('loading');

    const { getLanguage } = useLanguage();

    const getProjects = () => {
        let newProjectList = [].concat(projectListJson.projects);
        newProjectList.forEach((project) => {
            project["active"] = false;
        });
        setProjectList(newProjectList)
        setLoading(false)
    }

    useEffect(() => {
        getProjects();
    }, []);

    const getButtons = () => {
        if (projectList) {
            return projectList.map((project, i) => {
                return (<button onClick={() => {
                    changeProjectListActive(project)
                }} key={i} className={"buttonYears"}>{project.id}</button>)
            });
        }
    }

    const changeProjectListActive = (project) => {
        setImageLoaded('loading');
        setActiveProject(project)
    }

    const setFileStatus = (status) => {
        setImageLoaded(status)
    }

    const getProjectImage = (imgUrl) => {
        return (<div>{(() => {
                let status = {
                    'loading': () => {
                        return (<Spinner/>)
                    },
                    'loaded': () => {
                        return null
                    },
                    'failed to load': () => {
                        return (<p>{imageLoaded}</p>)
                    },
                    'no image to load': () => {
                        return (<p>{imageLoaded}</p>)
                    },
                };
                return status[imageLoaded]()
            })()}<img onLoad={setFileStatus.bind(this, 'loaded')}
                      onError={setFileStatus.bind(this, 'failed to load')}
                      onClick={() => {
                          openPopUpMenu(imgUrl)
                      }} className="project-image" src={imgUrl} alt=""/>
            </div>
        )
    }

    const getGitHubLink = (link) => {
        if (link) {
            return (<a href={link} target="_blank" rel="noopener noreferrer" className={"gitHubLinkButton"}>GitHub</a>)
        }
    }

    const getPosterLink = (link) => {
        if (link) {
            return (<a href={link} target="_blank" rel="noopener noreferrer" className={"gitHubLinkButton"}>Poster</a>)
        }
    }

    const getDemoVideo = (video) => {
        if (video) {
            return (
                <a href={video} target="_blank" rel="noopener noreferrer" className={"gitHubLinkButton"}>Demo video</a>)
        }
    }

    const returnImagePopUp = () => {
        if (isModalOpen && modalImgUrl) {
            let productImage = modalImgUrl;
            return (
                <div className={'popup-menu'}>
                    <div className={"popup-container"}>
                        <div className={`popup-textcontain`}>
                            <p onClick={() => {
                                closePopUpMenu()
                            }} className={"close"}>X</p>
                            <div className={"download-image-container"}>
                                <img className={"imageLarge"} src={productImage} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={"overlay"} onClick={() => {
                        closePopUpMenu();
                    }}/>
                </div>
            )
        }
    }

    const openPopUpMenu = (url) => {
        toggleModal(true)
        setModalImgUrl(url)
    }

    const closePopUpMenu = () => {
        toggleModal(false)
    }

    const getActiveProjectContent = () => {
        if (activeProject && projectList) {
            let uiLang = getLanguage();
            if (activeProject.hasOwnProperty(uiLang)) {
                return activeProject[uiLang].map((project, index) => {
                    return (<div key={index}>
                        <h2 className={"project-h2"}>{project.name}</h2>
                        {getGitHubLink(project.url)}
                        {getPosterLink(project.posterurl)}
                        {getDemoVideo(project.demovideo)}
                        <div className={"projectDiv"}>
                            <p className={"projectDescription"}>{project.kuvaus}</p>
                            {getProjectImage(project.imgurl)}
                        </div>
                    </div>)
                });
            }
        }
    }

    const checkActive = () => {
        if(activeProject) {
            return(activeProject.id)
        }
    }

    if (isLoading) {
        return (<h1>Loading...</h1>)
    }

    return (
        <div className={"project-content"}>
            {getButtons()}
            <h1 className={"homeHeader"}>Projects {checkActive()}</h1>
            {getActiveProjectContent()}
            {returnImagePopUp()}
        </div>
    )
}