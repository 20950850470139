import React from 'react';
import "../styles/home.css";

import {useNavigate} from 'react-router-dom';
import {useLanguage} from "../common/LanguageSelector";

export const Home = () => {
    const { getLanguage, setLanguage } = useLanguage();
    const navigate = useNavigate();
    const goToProjects = () => {
        navigate("/projects");
    }
    const getLanguageTextsForHome = () => {
        let uiLang = getLanguage();
        if (uiLang === "eng") {
            return (
                <p className={"homeText"}>
                    Hello and thank you for visiting my portfolio! As a highly skilled software developer, I have a passion for creating innovative solutions that push the boundaries of what is possible. In this portfolio, I am pleased to present some of the projects I have completed during my studies and in my free time. These projects showcase my ability to collaborate with others, manage the development process, and stay up-to-date with the latest technologies. Please feel free to take a look around and get in touch if you have any questions.
                </p>
            )
        } else {
            return (
                <p className={"homeText"}>Hei ja kiitos vierailustasi portfolioni parissa! Olen erittäin taitava ohjelmistokehittäjä, jolla on intohimo luoda innovatiivisia ratkaisuja. Tässä portfoliossa olen iloinen voidessani esitellä joitakin koulussa ja vapaa-ajalla tekemiäni projekteja. Nämä projektit osoittavat kykyni tehdä yhteistyötä muiden kanssa, hallita kehitysprosessia ja pysyä ajan tasalla uusimmista teknologioista. Voit tutustua projekteihini vapaasti ja ottaa yhteyttä, jos sinulla herää kysyttävää.
                </p>
            )
        }
    }

    return (
        <div className={"homeDiv"}>
            <img className={"lang-flag"} src={"/finflag.png"} onClick={() => {
                setLanguage("fin")
            }} alt={""}/>
            <img className={"lang-flag"} src={"/engflag.png"} onClick={() => {
                setLanguage("eng")
            }} alt={""}/>

            <h1 className={"homeHeader"}>PORTFOLIO</h1>
            {getLanguageTextsForHome()}

            <div onClick={() => {
                goToProjects()
            }} className="btn from-right">Projects
            </div>

            <img className="logoHome" src={"/20180805_140923.jpg"} alt=""/>
        </div>
    )
}