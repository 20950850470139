var projectListJson = {
    "projects": [
        {
            "id": "2017",
            "year": "2017",
            "fin": [
                {
                    "id": "2017_0_fin",
                    "name": "Tasapainorobotti",
                    "kuvaus": "Projektin tavoitteena oli toteuttaa tasapainorobotti, joka yrittää pysyä pystyssä kahdella pyörällä ja korjaa tasapainonsa tarpeen vaatiessa.",
                    "url": "https://github.com/Pastamamba/Selfbalancing-robot",
                    "imgurl": "https://drive.google.com/uc?id=11_O9HPNmTCLHNzDM0c75JAg7-szYhX_L",
                    "posterurl": "https://drive.google.com/uc?id=1h_9a7oyfl5hFaq0Pb5vTx52Ar3MAcWPT",
                    "imgurl2": "https://drive.google.com/uc?id=1WL5QiQXvdxTTLcOGq8TRyLavJgZZtPa-",
                },
                {
                    "id": "2017_1_fin",
                    "name": "Pankkisimulaattori",
                    "kuvaus": "Projektissa tehtiin pankkiautomaattisimulaatio käyttäen Qt Creator -kehitysympäristöä, RFID-kortinlukijaa ja -korttia.",
                    "imgurl": "https://drive.google.com/uc?id=1iboXbsWEHvdem1Z87IlLRDvK6-L3g2z1",
                    "posterurl": "https://drive.google.com/open?id=1x3ZrykVKdB-6vGBK4Q_8dfLxC-7koHm1",
                    "demovideo": "https://drive.google.com/open?id=1-WeEicd-XTByIlNc7Ponl5NHmfXDRoTL",
                },
            ],
            "eng": [
                {
                    "id": "2017_0_eng",
                    "name": "Self balancing robot",
                    "kuvaus": "The objective of the project was to make a self balancing robot, which could keep its balance on two wheels.",
                    "url": "https://github.com/Pastamamba/Selfbalancing-robot",
                    "imgurl": "https://drive.google.com/uc?id=11_O9HPNmTCLHNzDM0c75JAg7-szYhX_L",
                    "posterurl": "https://drive.google.com/uc?id=1h_9a7oyfl5hFaq0Pb5vTx52Ar3MAcWPT",
                    "imgurl2": "https://drive.google.com/uc?id=1WL5QiQXvdxTTLcOGq8TRyLavJgZZtPa-",
                },
                {
                    "id": "2017_1_eng",
                    "name": "Bank simulator",
                    "kuvaus": "The main purpose was to make an ATM simulation using the Qt Creator development environment, a RFID card reader and a RFID card.",
                    "imgurl": "https://drive.google.com/uc?id=1iboXbsWEHvdem1Z87IlLRDvK6-L3g2z1",
                    "posterurl": "https://drive.google.com/open?id=1x3ZrykVKdB-6vGBK4Q_8dfLxC-7koHm1",
                    "demovideo": "https://drive.google.com/open?id=1-WeEicd-XTByIlNc7Ponl5NHmfXDRoTL",
                },
            ]
        },

        {
            "id": "2018",
            "year": "2018",
            "fin": [
                {
                    "id": "2018_0_fin",
                    "name": "Valvontajärjestelmä",
                    "kuvaus": "Tavoitteena oli tehdä järjestelmä, jolla voidaan valvoa tilaa liike- ja ovianturin avulla ja ilmoittaa käyttäjälle tunkeilijoista.\n" +
                        "\n" +
                        "Käyttöliittymänä toimii verkkosivu, jossa voidaan hallinnoida hälytyksiä sekä seurata tapahtumia kuvien ja lokien avulla.\n" +
                        "\n" +
                        "Järjestelmä mittaa huoneen keskilämpötilaa vuorokauden välein sekä näyttää etusivulla viimeisimmän tunnin lämpötilan. \n",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/Projekti_Puumala_Tarkiainen_Saraj-rvi",
                    "imgurl": "https://drive.google.com/uc?id=1Bps34N-rILNGD-pbC72fDuqbaAAU3XgS",
                    "posterurl": "https://drive.google.com/open?id=1BPQ24TPrJS_7tJv6JNjw6UzL0aPlaImf",
                    "demovideo": "https://drive.google.com/open?id=1ArrTSU9UQnfOTpaGz2uCqXe8mobAwOqe"
                },
                {
                    "id": "2018_1_fin",
                    "name": "Quiz App",
                    "kuvaus": "Projektissa teimme tietovisan pelattavaksi verkkoselaimelle ja mobiiliapplikaatiolle. Tietovisa tallenetaa tietokantaan käyttäjien parhaat tulokset.",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/quiz-project-frontend",
                    "imgurl": "https://drive.google.com/uc?id=1H2YvrKGmKOUNToaLZfpwq426aagwQdJr",
                    "demovideo": "https://drive.google.com/open?id=1jbqPv2yOjXqkqto8oi1KZWj8WkWG8O5s"
                },
                {
                    "id": "2018_2_fin",
                    "name": "Vuokramökin kotisivu",
                    "kuvaus": "Toimeksiantona oli luoda toimivat kotisivut vuokramökille. Kotisivuilla kerrotaan mökin perustiedot, hinnasto, varausehdot sekä yhteystiedot.",
                    "imgurl": "https://drive.google.com/uc?id=1Y_wnwuwlmTbvAB02X0WG3u9PVN9zufab",
                },
            ],
            "eng": [
                {
                    "id": "2018_0_eng",
                    "name": "Monitoring system",
                    "kuvaus": "The main goal was to make a system that can monitor the space with a motion and door sensor and notify user of intruders.\n" +
                        "\n" +
                        "The interface is a web page where you can manage alarms and track events with images and logs. \n" +
                        "\n" +
                        "The system measures the average room temperature every 24 hours and displays the last hour average temperature on the front page.",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/Projekti_Puumala_Tarkiainen_Saraj-rvi",
                    "imgurl": "https://drive.google.com/uc?id=1Bps34N-rILNGD-pbC72fDuqbaAAU3XgS",
                    "posterurl": "https://drive.google.com/open?id=1BPQ24TPrJS_7tJv6JNjw6UzL0aPlaImf",
                    "demovideo": "https://drive.google.com/open?id=1ArrTSU9UQnfOTpaGz2uCqXe8mobAwOqe"
                },
                {
                    "id": "2018_1_eng",
                    "name": "Quiz App",
                    "kuvaus": "The aim of the project was to make a web and mobile based quiz application that stores the best scores of the users in a database.",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/quiz-project-frontend",
                    "imgurl": "https://drive.google.com/uc?id=1H2YvrKGmKOUNToaLZfpwq426aagwQdJr",
                    "demovideo": "https://drive.google.com/open?id=1jbqPv2yOjXqkqto8oi1KZWj8WkWG8O5s"
                },
                {
                    "id": "2018_2_eng",
                    "name": "Rental cottage homepage",
                    "kuvaus": "The assignment was to create a functional website for the rental cottage. \n" +
                        "The homepage provides basic information about the cottage, price list, booking conditions \n" +
                        "and contact information.",
                    "imgurl": "https://drive.google.com/uc?id=1Y_wnwuwlmTbvAB02X0WG3u9PVN9zufab",
                },
            ]
        },

        {
            "id": "2019",
            "year": "2019",
            "fin": [
                {
                    "id": "2019_0",
                    "name": "TicTacBot",
                    "kuvaus": "Projekti oli osa Basics of IoT -kurssia. Projektin päätavoitteena oli kehittää ristinollaa pelaava chat-robotti VoIP-alusta Discordille. Tietokantaan tallennettiin myös parhaita voittoaikoja.",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/tic-tac-bot",
                    "imgurl": "https://drive.google.com/uc?id=1_jyXBFxBlrmLTa_NffO9QQ-sn-MbsVC4",
                    "posterurl": "https://drive.google.com/open?id=1063zbiWJ2UbvIZJlkJEGThob7nOO4-iQ"
                },
                {
                    "id": "2019_1",
                    "name": "Unreal shooter",
                    "kuvaus": "Projektissa toteutettiin sivustapäinkuvattu 3D-räiskintämoninpeli, jota pelataan toisten pelaajien kanssa internetin välityksellä. \n" +
                        "\n" +
                        "Pelinkehitysprojektin tavoitteena oli toteuttaa räiskintäpelille ominaiset pelimekaniikat Unreal Engine 4:n kehitysympäristössä sekä luoda peliin useita karttoja, lisätä karttoihin maasta nostettavia aseita sekä ammus- ja parannuslaatikoita. ",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/unreal-shooter",
                    "imgurl": "https://drive.google.com/uc?id=1U6GSKJuACaNi6ZFJFsEFQhl7d1rmecjq",
                    "posterurl": "https://drive.google.com/open?id=14UhhJpuL76bo-IOY1Zv_X9Wg7Gke_JnN",
                    "demovideo": "https://drive.google.com/open?id=1OACzfhYkTjBLn2ldzgupx9Sjhok4im1Q"
                },
                {
                    "id": "2019_3",
                    "name": "Oheismyyntiverkkosivut",
                    "kuvaus": "Yritys tilasi nettisivut tuotteiden selailuun ja hakemiseen. Jatkokehityksenä on tehdä toimiva mobiiliapplikaatio tuotetietojen muokkaamiseen.",
                    "imgurl": "https://drive.google.com/uc?id=1NTDyeeyVUJgisbwm6Ia1bITFdQTih0I_"
                },
                {
                    "id": "2019_3",
                    "name": "Laaja mobiili- sekä web-applikaatio",
                    "kuvaus": "Kesätyössäni testailin laajaa mobiiliapplikaatiota ja toteutin samoja toiminnallisuuksia verkkoselaimelle React-kirjaston avulla.",
                },
            ],

            "eng": [
                {
                    "id": "2019_0_eng",
                    "name": "TicTacBot",
                    "kuvaus": "The project was part of the Basics of IoT course. The main aim of the project was to develop a functional tic tac toe game chat bot for the popular VoIP platform Discord.",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/tic-tac-bot",
                    "imgurl": "https://drive.google.com/uc?id=1_jyXBFxBlrmLTa_NffO9QQ-sn-MbsVC4",
                    "posterurl": "https://drive.google.com/open?id=1063zbiWJ2UbvIZJlkJEGThob7nOO4-iQ"
                },
                {
                    "id": "2019_1_eng",
                    "name": "Unreal shooter",
                    "kuvaus": "The aim of the game project was to implement shooter game mechanics in the Unreal Engine 4 development environment.  \n" +
                        "The most important objective of the project was to create a working network multiplayer implementation with \n" +
                        "sufficient performance.",
                    "url": "https://github.com/PuumalaTarkiainenSarajarvi/unreal-shooter",
                    "imgurl": "https://drive.google.com/uc?id=1U6GSKJuACaNi6ZFJFsEFQhl7d1rmecjq",
                    "posterurl": "https://drive.google.com/open?id=14UhhJpuL76bo-IOY1Zv_X9Wg7Gke_JnN",
                    "demovideo": "https://drive.google.com/open?id=1OACzfhYkTjBLn2ldzgupx9Sjhok4im1Q"
                },
                {
                    "id": "2019_2_eng",
                    "name": "Supplementary sales website",
                    "kuvaus": "The aim was to make a functional website for browsing and retrieving company products. \n" +
                        "Further development will be to make a functional mobile application for editing product information.",
                    "imgurl": "https://drive.google.com/uc?id=1NTDyeeyVUJgisbwm6Ia1bITFdQTih0I_"
                },
                {
                    "id": "2019_3_eng",
                    "name": "Extensive mobile and web application",
                    "kuvaus": "A summer job was to test a mobile application and implement its functionalities to a web browser using the React library",
                },
            ]
        },

        {
            "id": "2022 ->",
            "year": "2022",
            "fin": [
                {
                    "id": "2022_0",
                    "name": "Jobr",
                    "kuvaus": "Vapaa-ajallani työskentelen innokkaasti projektin parissa, joka keskittyy työpaikkahakemusten helpompaan löytämiseen ja työnhakijoiden ja työnantajien yhdistämiseen. Projekti on kehitteillä käyttäen Flutter- ja Dart-ohjelmointikieliä, jotta se toimii saumattomasti kaikilla puhelinalustoilla. Tavoitteenamme on kehittää intuitiivinen käyttöliittymä, jonka avulla käyttäjät voivat löytää helposti työpaikkailmoituksia ja kommunikoida potentiaalisten työnantajien kanssa. Olen erittäin motivoitunut tämän projektin parissa ja teen jatkuvasti töitä sen kehittämiseksi. Toivon, että tulevaisuudessa tämä projekti voi auttaa monia ihmisiä löytämään unelmiensa työpaikan.",
                    "imgurl": "https://drive.google.com/uc?id=1CT6BTeTJ7fIitocUlGmOsF3g-eKZtS1u",
                },

            ],

            "eng": [
                {
                    "id": "2022_0",
                    "name": "Jobr",
                    "kuvaus": "In my free time, I am actively working on a project that focuses on making job searching easier and connecting job seekers with employers. The project is being developed using Flutter and Dart programming languages to ensure seamless operation across all mobile platforms. Our goal is to develop an intuitive user interface that enables users to easily find job postings and communicate with potential employers. I am highly motivated to work on this project and am constantly striving to improve it. My hope is that in the future, this project will help many people find their dream job.",
                    "imgurl": "https://drive.google.com/uc?id=1CT6BTeTJ7fIitocUlGmOsF3g-eKZtS1u",
                },
            ]
        },
    ]
};
export default projectListJson;