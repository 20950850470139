import React, {useEffect, useState} from 'react';
import "../styles/quicklook.css";
import ChartListJson from "../projects_list/ChartListJson";
import {useLanguage} from "../common/LanguageSelector";

export const QuickLook = (props) => {
    const [skillsList, setSkillsList] = useState(null);
    const { getLanguage } = useLanguage();

    useEffect(() => {
        getSkillsCharts();
    }, []);

    const getSkillsCharts = () => {
        let chartList = [].concat(ChartListJson.charts);
        chartList.forEach((category) => {
            category["active"] = false;
        });
        setSkillsList(chartList)
    }
    const getBalls = (skill) => {
        let number = skill.toString();
        let ballArray = [];
        for (let i = 0; i < 5; i++) {
            if (number > i) {
                ballArray.push(true);
            } else {
                ballArray.push(false)
            }
        }
        return ballArray.map((ball, i) => {
            if (ball) {
                return (<span key={i} className={"dot"}/>)
            } else {
                return (<span key={i} className={"empty-dot"}/>)
            }
        })
    }

    const getCharts = () => {
        if (skillsList) {
            return skillsList.map((itm, i) => {
                return checkActiveCategory(itm, i);
            });
        }
    }

    const checkActiveCategory = (category, index) => {
        if (category.active) {
            return (<div key={index} onClick={() => {
                setActive(category)
            }} className={"skill-language-div"}>
                <h1 className={"category-h1"}>
                    <span className={"plus-sign"}>-</span>
                    {category[getLanguage()]}
                </h1>
                {getLanguages(category.languages)}
            </div>)
        } else {
            return (<div onClick={() => {
                setActive(category)
            }} key={index}>
                <h1 className={"category-h1"}>
                    <span className={"plus-sign"}>+</span>
                    {category[getLanguage()]}
                </h1>
            </div>)
        }
    }

    const getLanguages = (language) => {
        return language.map((itm, i) => {
            return (<div className={"dots-div"} key={i}>
                <p className={"balls-p"}>{itm.language}</p>
                {getBalls(itm.skill)}
            </div>)
        });
    }

    const setActive = (category) => {
        let array = [].concat(skillsList);
        array.forEach((itm, i) => {
            if (category[getLanguage()] === itm[getLanguage()]) {
                itm.active = !itm.active;
            }
        });
        setSkillsList(array);
    }

    const language = getLanguage();

    const getKnowledgeTable = () => {
        if (language === "eng") {
            return (<div>
                <h1 className={"quicklookdiv-h1"}>Skills</h1>
                <p className={"quicklookdiv-p"}> 1 = Basics</p>
                <p className={"quicklookdiv-p"}> 2 = Ok </p>
                <p className={"quicklookdiv-p"}> 3 = Good</p>
                <p className={"quicklookdiv-p"}> 4 = Creditable</p>
                <p className={"quicklookdiv-p"}> 5 = Expert</p>
            </div>)
        } else {
            return (<div>
                <h1 className={"quicklookdiv-h1"}>Osaaminen</h1>
                <p className={"quicklookdiv-p"}> 1 = Perusteet</p>
                <p className={"quicklookdiv-p"}> 2 = Tyydyttävä</p>
                <p className={"quicklookdiv-p"}> 3 = Hyvä</p>
                <p className={"quicklookdiv-p"}> 4 = Kiitettävä</p>
                <p className={"quicklookdiv-p"}> 5 = Erinomainen</p>
            </div>)
        }
    }

    return (
        <div className={"quicklookdiv"}>
            {getKnowledgeTable()}
            {getCharts()}
        </div>
    )
}